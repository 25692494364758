define(['app', '$window', 'accessibleModalHelper', 'siteObj'], function (app, $window, accessibleModalHelper, siteObj) {
  const emailRegex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$');

  const parseQuery = (url) => {
    if (!/\?/.test(url)) return {};
    const queryString = url.match(/\?(.*)/)[1];
    return queryString.split('&').reduce((acc, q) => {
      const [k, v] = q.split('=');
      return Object.assign({}, acc, {
        [k]: window.decodeURIComponent(v),
      });
    }, {});
  };

  const isValidEmail = (email) => emailRegex.test(email);

  const emailReengagement = () => {
    let self = {};
    self.app = app;

    const _config = {
      newsletterModal: '[data-js-element=emailReengagement-element]',
      cookieName: 'emailReEngagementCookie',
      cookieValue: {
        subscribed: 'newsletter-subscribed',
        rejected: 'newsletter-rejected',
      },
      closeButton: '[data-js-element=emailreengagement-modal-close-button]',
      continueButton: '[data-js-element=emailReengagement_continue_button]',
      failContinueButton: '[data-js-element=emailReengagement_continue_button_fail]',
      form: '[data-component=emailReengagementForm]',
      captchaResponseAttributeName: 'g-recaptcha-response',
      captchaResponseAttributeId: 'g-recaptcha-response-input',
      inAppDisableSpan: '[data-js-element=disableInApp]',
      invisibleRecaptchaSubmitButton: '.invisible_recaptcha',
      submitButton: '.emailReengagement_newsletterForm_submit',
      submitHiddenInput: 'emailReengagementForm_submitBtn'
    };

    const _classNames = {
      container: 'emailReengagement_form_container',
      formSection: 'emailReengagement_newsletterForm',
      form: 'emailReengagement_newsletterForm_form',
      returnToInput: 'emailReengagement_newsletterForm_returnTo',
      input: 'emailReengagement_newsletterForm_input',
      title: 'emailReengagement_newsletterForm_title',
      closeButton: 'emailReengagement_modal-close-button',
      tryAgainButton: 'emailReengagement_tryAgain_button',
      failContinueButton: 'emailReengagement_continue_button_fail',
      successSection: 'emailReengagement_success',
      invalidSection: 'emailReengagement_incorrect',
      errorSection: 'emailReengagement_error',
      duplicateSection: 'emailReengagement_duplicate'
    };

    const _init = (element) => {
      self.element = element;
      self.continueButtons = Array.prototype.slice.call(document.querySelectorAll(self.config.continueButton));
      self.failContinueButton = document.querySelector(self.config.failContinueButton);
      self.closeButton = document.querySelector(self.config.closeButton);
      self.newsletterModalElement = document.querySelector(self.config.newsletterModal);
      self.invisibleRecaptchaSubmitButton = document.querySelector(self.config.invisibleRecaptchaSubmitButton);
      self.submitButton = document.querySelector(self.config.submitButton);
      self.submitHiddenInput = document.getElementById(self.config.submitHiddenInput);

      if (siteObj.features.delayModalOnInitialLoad !== 'true') {
        self.setModalTimeout()
      }

      self.children = {};
      for (const [key, className] of Object.entries(_classNames)) {
        self.children[key] = element.querySelector(`.${className}`);
      }

      self.children.returnToInput.value = parseQuery($window.location.href)['returnTo'] || '';

      self.attachListeners();

      return self;
    };


    const _attachListeners = () => {
      self.invisibleRecaptchaSubmitButton && self.invisibleRecaptchaSubmitButton.addEventListener('click', self.onClick);

      self.children.form.addEventListener('keypress', e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          self.submitButton.click();
        }
      });

      self.children.form.addEventListener('submit', self.onSubmit);
      self.children.tryAgainButton.addEventListener('click', () => self.showSection('form'));
      self.element.addEventListener('click', event => {
        if (event.target === self.element) {
          event.stopPropagation();
          self.closeButtonHandler();
        }
      });

      if (siteObj.features.delayModalOnInitialLoad === 'true') {
        window.addEventListener('load', self.pageLoaded);
      }
    };

    const _pageLoaded = () => {
      self.calculatePageCounts();
      self.setModalTimeout();
    };

    const _setModalTimeout = () => {
      let timeout = 2000;

      if (self.element.getAttribute('data-delay')) {
        timeout = self.element.getAttribute('data-delay');
      }
      setTimeout(function () {
        self.checkCookieExists();
      }, timeout);
    };

    const _onClick = e => {
      e.preventDefault();
      if (!self.children.form.checkValidity()) {
        self.submitHiddenInput.click();
        return;
      }
      $window.grecaptcha.execute();
    };

    const _onSubmit = e => {
      e.preventDefault();
      const email = self.children.input.value;
      if (!isValidEmail(email)) return self.showSection('invalid');
      const postUrl = 'https://' + window.location.host + '/' + self.children.form.getAttribute('action');

      let GOOGLE_RECAPTCHA_RESPONSE = document.querySelector('#g-recaptcha-response');
      GOOGLE_RECAPTCHA_RESPONSE = GOOGLE_RECAPTCHA_RESPONSE ? GOOGLE_RECAPTCHA_RESPONSE.value : '0';

      let captchaInputEl = document.getElementById(self.config.captchaResponseAttributeId);
      if (captchaInputEl) {
        captchaInputEl.remove();
      }

      let captchaInput = document.createElement('input');
      captchaInput.name = self.config.captchaResponseAttributeName;
      captchaInput.id = self.config.captchaResponseAttributeId;
      captchaInput.value = GOOGLE_RECAPTCHA_RESPONSE;
      captchaInput.type = 'hidden';

      let formEl = document.querySelector(self.config.form);
      formEl.appendChild(captchaInput);

      self.postForm(postUrl, self.serializeForm())
        .then(() => self.showSection('success', ''))
        .catch(() => {
        });
    };

    const _postForm = (url, data) => new Promise((res, rej) => {

      app.ajax.post({
        url,
        send: data,
        requestHeader: {
          header: 'content-type',
          value: 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        success: res,
        error: (error, status) => {
          if (status.includes('409')) {
            self.showSection('duplicate');
          } else if (status.includes('500')) {
            self.showSection('error');
          }
        },
      });
    });

    const _showSection = (section) => {
      if(section === 'success') {
        window.dataLayer.push({
          event: 'customEvent',
          eventData: {
            eventCategory: 'newsletter',
            eventAction: 'successful sign-up',
            eventLabel: '',
            eventPage: ''
          }
        });
      }
      self.hideAllSections();
      section = `${section}Section`;
      self.children[section].classList.add(`${_classNames[section]}-show`);
      self.modalHelper.load(document.querySelector(`#${_classNames[section]}-title`));
    };

    const _serializeForm = () => {
      const encode = (s) => window.encodeURIComponent(s);
      const inputs = self.children.form.querySelectorAll('input[name]');
      return Array.from(inputs).map(({name, value}) => {
        return `${encode(name)}=${encode(value)}`;
      }).join('&');
    };

    const _hideAllSections = () => {
      const sections = [
        'formSection',
        'successSection',
        'invalidSection',
        'errorSection',
        'duplicateSection',
      ];
      for (const section of sections) {
        self.children[section].classList.remove(`${_classNames[section]}-show`);
      }
    };

    /**
     * Set cookie name, value and expiration date
     */
    const _setCookie = (name, value) => {
      let path = '/'; // it has to be root for the cookie to be visible on the entire website
      let expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 5);

      document.cookie = name + '=' + value + '; expires=' + expirationDate.toUTCString() + '; path=' + path;
    };

    /**
     *  The method is later used to check if the cookie exists in the user's browser
     */
    const _getCookie = (name) => {
      const cookieName = name + '=';
      const ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(cookieName) == 0) return c.substring(cookieName.length, c.length);
      }
      return null;
    };

    const _continueButtonHandler = () => {
      app.publish('tracking/record', 'email re-engagement Modal', 'Clicked', 'Continue Modal Button');
      self.newsletterModalElement.style.display = 'none';
      self.newsletterModalElement.classList.add('hidden');
      document.body.classList.remove('emailReengagement_noscroll');

      self.modalHelper && self.modalHelper.close();
    };

    const _closeButtonHandler = () => {
      app.publish('tracking/record', 'email re-engagement Modal', 'Clicked', 'Close Modal Button');
      self.newsletterModalElement.style.display = 'none';
      self.newsletterModalElement.classList.add('hidden');
      document.body.classList.remove('emailReengagement_noscroll');

      self.modalHelper && self.modalHelper.close();

      const url = 'https://' + window.location.host + '/rejectNewsletter.account';

      app.ajax.post({
        url,
        requestHeader: {
          header: 'content-type',
          value: 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });
    };

    /**
     * If cookie exists hide element, if not show it and wait for click event
     */
    const _checkCookieExists = () => {
      let pageCount = Number(localStorage.getItem('pageCount'));
      if (siteObj.features.delayModalOnInitialLoad !== 'true' || pageCount > 3) {
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        var isInApp =  (ua.indexOf('facebookexternalhit') > -1 || ua.indexOf('Instagram') > -1) ? true : false;

        if (self.getCookie(self.config.cookieName) || document.querySelector('.internationalOverlay_popup.active') || isInApp == true && document.querySelector(self.config.inAppDisableSpan)) {
          self.newsletterModalElement.style.display = 'none';
        } else {
          app.publish('tracking/record', 'reEngagement Tooltip Modal', 'Shown', 'reEngagement Modal Message');
          self.continueButtons.map(button =>  button.addEventListener('click', self.continueButtonHandler));
          self.failContinueButton.addEventListener('click', () => self.showSection('form'));
          self.closeButton.addEventListener('click', self.closeButtonHandler);

          self.newsletterModalElement.style.display = 'flex';

          document.body.classList.add('emailReengagement_noscroll');

          self.modalHelper = new accessibleModalHelper(
              document.querySelector(`.${_classNames.container}`),
              self.closeButtonHandler,
              document.querySelector(`.${_classNames.title}`)
          );
        }
      }
    };

    const _calculatePageCounts = () => {
      let session = window.localStorage, pageCount;
      if(!session.getItem("pageCount")){
        session.setItem('pageCount', '1');
      } else {
        pageCount = Number(session.getItem("pageCount")) + 1;
        session.setItem('pageCount', pageCount );
      }
    }

    self.config = _config;
    self.init = _init;
    self.setCookie = _setCookie;
    self.pageLoaded = _pageLoaded;
    self.setModalTimeout = _setModalTimeout;
    self.getCookie = _getCookie;
    self.checkCookieExists = _checkCookieExists;
    self.attachListeners = _attachListeners;
    self.hideAllSections = _hideAllSections;
    self.serializeForm = _serializeForm;
    self.showSection = _showSection;
    self.postForm = _postForm;
    self.onSubmit = _onSubmit;
    self.onClick = _onClick;
    self.continueButtonHandler = _continueButtonHandler;
    self.closeButtonHandler = _closeButtonHandler;
    self.calculatePageCounts = _calculatePageCounts;
    return self;
  };

  return emailReengagement;

});
